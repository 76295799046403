body {
  width: 100vw;
  height: 100vh;
 background-color: #161527;
  /* 임시 */
  /* overflow-x: hidden; */
  overflow: hidden;
  /* background-color:"black"; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea::placeholder{
  color: white;
  font-weight: 600;
}
/* 스크롤바의 배경을 투명하게 설정 */
img{
  -webkit-user-drag: none;
	user-drag: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
code {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.kakao-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.kakao-login-button::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  z-index: 1;
}

.kakao-login-button .kakao-login-button-text {
  visibility: hidden;
}