.App {
  display:'flex';
  width:"100vw";
  height: '100vh';
  align-items:"flex-start";
  justify-content:"center";
  background-color:#161527;
  padding-top:"16px";
  padding-bottom:"16px";
  text-align: center;

}
.glass{
  background: rgba(255, 255, 255, 0.22);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
